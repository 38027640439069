import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NewsletterContent from '../../components/tsx/NewsletterContent';
import DialogWrapper from '../../components/tsx/DialogWrapper';
import styles from "../css/Newsletter.module.css";

interface IProps extends RouteComponentProps<{ id: string }> {}
interface ISubscription {
    id: number;
    name: string;
}

interface ISubscriber {
    settingsId: string;
    subscriptions: ISubscription[];
}

const Newsletter: React.FC<IProps> = ({ match }) => {

    return (
        <div className={styles.container}>
            <DialogWrapper>
                <Helmet>
                    <title>Newsletter Settings</title>
                    <meta name="description" content="Maethril Account Newsletter Settings" />
                </Helmet>
                <NewsletterContent userId={match.params.id} />
            </DialogWrapper>
        </div>
    );
}

export default Newsletter;
