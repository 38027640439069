import React from 'react';
import type { ISocialMediaProps } from '../../types/SocialMedia'
import * as Icons from './Icons';
import styles from '../css/SocialMedia.module.css';

const getIcon = (id: string) => {
    switch(id)
    {
        case "Blog":
            return <Icons.Blog />;
        case "Facebook":
            return <Icons.Facebook />;
        case "Instagram":
            return <Icons.Instagram />;
        case "LinkedIn":
            return <Icons.LinkedIn />;
        case "Reddit":
            return <Icons.Reddit />;
        case "Twitter":
            return <Icons.Twitter />;
        case "Web":
            return <Icons.Maethril />;
        case "YouTube":
            return <Icons.YouTube />;
    }
}

const SocialMedia: React.FC<ISocialMediaProps> = ({id, url}) => {
    function handleClick() : void {
        window.location.href = url;
    }

    return (
        <div className={styles.iconContainer} onClick={handleClick}>
            { getIcon(id) }
        </div>
    );
}

export default SocialMedia;
