import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configData from '../../config.json';
import styles from "../css/EmailVerificationContent.module.css";

interface IProps { userId: string };

const EmailVerificationContent: React.FC<IProps> = (props: IProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        axios({
            method: 'POST',
            url: `${configData.VERIFY_EMAIL_URL}/${props.userId}`
        })
            .then(() => {})
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
    }, []);

    if (loading)
    {
        return (
            <div className={styles.container}>
                Loading...
            </div>
        );
    }

    if (error)
    {
        return (
            <div className={styles.container}>
                {error}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            Email successfully verified!<br />Welcome to Maethril!
        </div>
    )
}

export default EmailVerificationContent;
