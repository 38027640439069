import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DialogWrapper from '../../components/tsx/DialogWrapper';
import EmailVerificationContent from '../../components/tsx/EmailVerificationContent';
import styles from "../css/EmailVerification.module.css";

interface IProps extends RouteComponentProps<{ id: string }> {}

const EmailVerification: React.FC<IProps> = ({ match }) => {
    return (
        <div className={styles.container}>
            <DialogWrapper>
                <Helmet>
                    <title>Email Verification</title>
                    <meta name="description" content="Maethril Account Email Verification" />
                </Helmet>
                <EmailVerificationContent userId={match.params.id} />
            </DialogWrapper>
        </div>
    );
}

export default EmailVerification;
