import axios from 'axios';
import React, { useEffect, useState } from 'react';
import configData from '../../config.json';
import styles from "../css/NewsletterContent.module.css";
import Checkbox from './Checkbox';

interface IProps { userId: string };
interface ISubscription {
    id: number;
    name: string;
}

interface ISubscriber {
    id: number;
    email: string;
    subscriptions: ISubscription[];
    settingsId: string;
}

interface ISubscriptionFilter {
    id: number;
    name: string;
    selected: boolean;
}

const NewsletterContent: React.FC<IProps> = (props: IProps) => {
    const [lastSettings, setLastSettings] = useState<ISubscriptionFilter[]>([]);
    const [subscriber, setSubscriber] = useState<ISubscriber>();
    const [subscriptions, setSubscriptions] = useState<ISubscriptionFilter[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);
    const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `${configData.NEWSLETTER_GET_URL}/${props.userId}`
        })
            .then(response => setSubscriber(response.data))
            .catch(error => setError(error.message))
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        if(!subscriber)
            return;

        axios
            .get<ISubscription[]>(configData.SUBSCRIPTIONS_URL)
            .then(response => {
                let subscriptions: ISubscriptionFilter[] = [];
                for(let i = 0; i < response.data.length; ++i)
                {
                    subscriptions.push({
                        id: response.data[i].id,
                        name: response.data[i].name,
                        selected: subscriber ? subscriber?.subscriptions.find(
                            obj => obj.name === response.data[i].name) !== undefined : false
                    });
                }

                setLastSettings(subscriptions);
                setSubscriptions(subscriptions)
            })
            .catch(error => setError(error));
    }, [subscriber]);

    function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>):void {
        let newSubscriptions: ISubscriptionFilter[] = [];
        subscriptions.forEach(obj => {
            let newTag: ISubscriptionFilter = {
                id: obj.id,
                name: obj.name,
                selected: obj.name === e.target.name ? !obj.selected : obj.selected
            }
            newSubscriptions.push(newTag);
        });

        setSubscriptions(newSubscriptions);
    }

    function handleSave():void {
        setError("");
        setSuccess(false);

        let settings = {
            settingsId: subscriber?.settingsId,
            subscriptions: subscriptions.filter(obj => obj.selected).map(obj => ({id: obj.id, name: obj.name}))
        }

        if (subscriptions === lastSettings)
        {
            setSuccess(true);
            return;
        }

        axios({
            method: 'PUT',
            url: configData.NEWSLETTER_EDIT_URL,
            data: settings
        })
            .then(response => {
                if(response.status === 204 && response.statusText === "No Content")
                {
                    setSuccess(true);
                    setLastSettings(subscriptions);
                }
            })
            .catch(error => setError(error.message));
    }

    function handleUnsubscribe():void {
        axios({
            method: 'DELETE',
            url: `${configData.NEWSLETTER_UNSUBSCRIBE_URL}/${subscriber?.settingsId}`,
        })
            .catch(error => setError(error.message));

        if (error)
            return;

        setUnsubscribed(true);
        setError("");
        setSuccess(false);
    }

    function handleSubscribeBack():void {
        axios({
            method: 'POST',
            url: configData.NEWSLETTER_ADD_URL,
            data: subscriber
        })
            .catch(error => setError(error.message));

        if (error)
            return;

        setUnsubscribed(false);
        setError("");
        setSuccess(false);
    }

    if (error)
    {
        return (
            <div className={styles.container}>
                {error}
            </div>
        );
    }

    if (loading)
    {
        return (
            <div className={styles.container}>
                Loading...
            </div>
        );
    }

    if (!subscriber)
    {
        return (
            <div className={styles.container}>
                Couldn't find subscriber
            </div>
        );
    }

    if (unsubscribed)
    {
        return (
            <div className={styles.container}>
                <header className={styles.titleContainer}>
                    <h1 className={styles.title}>We are sad to see you go</h1>
                </header>
                <div className={styles.actionContainer}>
                    <button className={styles.save} onClick={handleSubscribeBack}>I changed my mind</button>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <header className={styles.titleContainer}>
                <h1 className={styles.title}>Settings for<br/>{subscriber.email}</h1>
            </header>
            <ul className={styles.subscriptionsContainer}>
                {
                    subscriptions.length === 0 ?
                        <span className={styles.subscriptionsLoading}>Loading subscriptions...</span> :
                        subscriptions.map(subscription =>
                            <li className={styles.checkboxContainer} key={subscription.id}>
                                <Checkbox id={subscription.id}
                                        name={subscription.name}
                                        selected={subscription.selected}
                                        onChange={handleCheckboxChange} />
                            </li>
                        )
                }
            </ul>
            { success && <div className={styles.success}>Successfully updated</div> }
            { error && <div className={styles.error}>{error}</div> }
            <div className={styles.actionContainer}>
                <button className={styles.save} onClick={handleSave}>Save</button>
                <button className={styles.unsubscribe} onClick={handleUnsubscribe}>Unsubscribe</button>
            </div>
        </div>
    )
}

export default NewsletterContent;
