import { match, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DialogWrapper from '../../components/tsx/DialogWrapper';
import styles from "../css/NewPassword.module.css";
import { IoEyeOff, IoEye } from "react-icons/io5";
import { useState } from 'react';
import configData from "../../config.json";
import axios from 'axios';

interface IProps extends RouteComponentProps<{ id: string }> {}

const NewPassword: React.FC<IProps> = ({ match }) => {
    const [newPassword, setNewPassword] = useState<string>();
    const [repeatPassword, setRepeatPassword] = useState<string>();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<boolean>(false);

    const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setError("");

        if (newPassword !== repeatPassword)
        {
            setError("Passwords don't match.");
            return;
        }

        let passwordChangeDTO = {
            guid: match.params.id,
            password: newPassword
        }

        axios({
            method: 'POST',
            url: configData.NEW_PASSWORD_URL,
            data: passwordChangeDTO
        })
            .then(() => setSuccess(true))
            .catch(error => setError(error.message));
    }

    return (
        <div className={styles.container}>
            <DialogWrapper>
                <Helmet>
                    <title>New password</title>
                    <meta name="description" content="Maethril Account new password" />
                </Helmet>
                <div className={styles.contentContainer}>
                    {
                        success ?
                            <div className={styles.successContainer}>Success!</div> :
                            <form className={styles.passwordForm}>
                                <div className={styles.inputContainer}>
                                    <input className={styles.passwordInput}
                                        type={showPassword ? "text" : "password"}
                                        placeholder="New password"
                                        value={newPassword}
                                        onChange={e => setNewPassword(e.target.value)} />
                                    {
                                        showPassword ?
                                            <IoEye className={styles.icon} onClick={() => setShowPassword(false)} /> :
                                            <IoEyeOff className={styles.icon} onClick={() => setShowPassword(true)} />
                                    }
                                </div>
                                <div className={styles.inputContainer}>
                                    <input className={styles.passwordInput}
                                        type={showRepeatPassword ? "text" : "password"}
                                        placeholder="Repeat password"
                                        value={repeatPassword}
                                        onChange={e => setRepeatPassword(e.target.value)} />
                                    {
                                        showRepeatPassword ?
                                            <IoEye className={styles.icon} onClick={() => setShowRepeatPassword(false)} /> :
                                            <IoEyeOff className={styles.icon} onClick={() => setShowRepeatPassword(true)} />
                                    }
                                </div>
                                { error && <span className={styles.error}>{error}</span> }
                                <button className={styles.submitButton} onClick={handleSubmit}>New password</button>
                            </form>
                    }
                </div>
            </DialogWrapper>
        </div>
    );
}

export default NewPassword;
