import React, { useState, useEffect } from 'react';
import configData from '../../config.json';
import styles from '../css/Footer.module.css';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { ISocialMediaDTO } from '../../types/SocialMedia';
import SocialMedia from './SocialMedia';

const Footer: React.FC = () => {
    const [socialMedias, setSocialMedias] = useState<ISocialMediaDTO[]>([]);

    useEffect(() => {
        axios.get<ISocialMediaDTO[]>(configData.SOCIALMEDIA_URL)
            .then(response => setSocialMedias(response.data))
            .catch(error => {});
    }, []);

    return (
        <footer className={styles.container}>
            { socialMedias.length > 0 &&
                <div className={styles.socialMediaContainer}>
                {
                    socialMedias.map(socialMedia =>
                        socialMedia.url &&
                    <div key={socialMedia.id} className={styles.socialMediaWrapper}>
                        <SocialMedia id={socialMedia.id} url={socialMedia.url as string} />
                    </div>
                )}
                </div>
            }
            <div className={styles.linksContainer}>
                <a className={styles.bottomLink} href="https://blog.maethril.net/terms-of-service">
                    <span>Terms Of Service</span>
                </a>
                <span className={styles.bottomSpacer}></span>
                <a className={styles.bottomLink} href="https://blog.maethril.net/privacy-notice">
                    <span>Privacy Notice</span>
                </a>
                <span className={styles.bottomSpacer}></span>
                <a className={styles.bottomLink} href="https://blog.maethril.net/cookie-notice">
                    <span>Cookie Notice</span>
                </a>
            </div>
            <span className={styles.maethril}>© {new Date().getFullYear()} Maethril</span>
        </footer>
    );
}

export default Footer;
