import React from 'react';
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.css';
import Footer from './components/tsx/Footer';
import ScrollToTop from './components/tsx/ScrollToTop';
import EmailVerification from './pages/tsx/EmailVerification';
import NewPassword from './pages/tsx/NewPassword';
import Newsletter from './pages/tsx/Newsletter';
import NotFound from './pages/tsx/NotFound';

const App: React.FC = () => {
    return (
        <div className={styles.mainContainer}>
            <Router>
                <ScrollToTop />
                    <div className={styles.mainContent}>
                        <Switch>
                            <Route path="/verify/:id" component={EmailVerification} />
                            <Route path="/newsletter/:id" component={Newsletter} />
                            <Route path="/password/:id" component={NewPassword} />
                            <Route path="/" component={NotFound} />
                        </Switch>
                    </div>
                <Footer />
            </Router>
        </div>
    )
}

export default App;
