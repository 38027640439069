import { RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from "../css/NotFound.module.css";
import DialogWrapper from '../../components/tsx/DialogWrapper';

interface IProps extends RouteComponentProps<{ id: string }> {}

const EmailVerification: React.FC<IProps> = ({ match }) => {
    return (
        <div className={styles.container}>
            <Helmet>
                <title>Not Found</title>
                <meta name="description" content="Maethril Account Email Verification" />
            </Helmet>
            <DialogWrapper>
                <div className={styles.content}>
                    <h1>Page not found</h1>
                </div>
            </DialogWrapper>
        </div>
    );
}

export default EmailVerification;
