import styles from "../css/DialogWrapper.module.css";

interface IProps {
    children?:
        | JSX.Element
        | JSX.Element[]
        | string
        | string[]
}

const DialogWrapper: React.FC<IProps> = (props: IProps) => {
    return (
        <div className={styles.container}>
            <header>
                <img className={styles.logo} src="/media/Maethril-250x250.png" alt="Maethril logo" width={125} />
            </header>
            <div className={styles.contentContainer}>
                {props.children}
            </div>
        </div>
    )
}

export default DialogWrapper;
